import React from 'react'
import { Link } from 'gatsby'
import './style.css'

const Header = ({ siteTitle }) => (
  <div className="main-header">
    <div className="content-width">
      <div className="small-logo-container">
        <span className="logo-front">Juice</span><span className="logo-back">Free</span>
        <div className="tagline">Rake-free sports betting</div>
        </div>
    </div>
  </div>
)

export default Header
