import React from 'react'
import { Link } from 'gatsby'
import './style.css'
import { Grid, Icon, Header, Divider, Input, Button } from 'semantic-ui-react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import herovid from '../assets/juice-vid-new_3.mp4'
import heroPoster from "../assets/juice-poster.png"
import Layout from '../components/layout'
import oranges from "../assets/oranges.png"
import centerLogo from "../images/gatsby-icon.png"

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      isTop: false,
      email: '',
      // value: '',
      messageContent: '',
      message: false

    };

    this.handleScroll = this.handleScroll.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.removeMessage = this.removeMessage.bind(this);
   }

   handleChange(event) {
    this.setState({email: event.target.value});
  }


   handleForm(event){
    event.preventDefault();
    let email = this.state.email;
    console.log(email);
    addToMailchimp(email)
    // alert('An Email was submitted: ' + this.state.value);
    this.setState({
      email: '',
      message: true,
      messageContent: "Thanks! You should get a confirmation email from us any second. Talk to you soon and goodluck in the meantime!"
    }, () => {
      setTimeout(this.removeMessage, 3000)
    });
   }

   removeMessage() {
      this.setState({
        message: false
      });
  }



componentDidMount() {
  let passedHeight = document.getElementById('section-3').clientHeight + 100;
  window.addEventListener('scroll', this.handleScroll);
  this.setState({passedHeight:passedHeight});
    console.log(this.state.passedHeight)
}

componentWillUnmount() {
  window.removeEventListener('scroll', this.handleScroll);
}

handleScroll(event) {
  console.log(this.state)
  this.setState({
    y: window.scrollY
  });
  if (this.state.y > this.state.passedHeight) {
    this.setState({isTop:true})
  } else {
    this.setState({isTop:false})
  }
};

  render() {
    const scrollPassed = this.state.isTop ? 'blue-text' : '';
    return ( 
  <Layout>
    {/* <div className="outer-hero"> */}
    <div className="hero-container">
      <div className={`hero-text ${scrollPassed}`}>Sportsbooks love taking fees. We're juice free.</div>
      <video className="hero-video" src={herovid} autoPlay muted loop poster={heroPoster}/>
      {/* <div className="hero-text">Nur tote Fische schwimmen mit dem Strom</div> */}
    </div>
    {/* </div> */}
  
  <div className="first-grid">
    <Grid columns={3} >
      <Grid.Row columns={1} className="white-row">
      <div className="first-row-content-wrap">
        {/* <Grid.Column  > */}
          {/* <Icon name="dollar" size="big" /> */}
          <Header as="h3"><span className="header-label">1. </span>
          
          Bookies and sports books charge a huge rake.</Header>
          <p>Bookies and sportsbooks make their money by charging an outrageous amount of juice on their bets. Sometimes all you want to do is make a simple bet on a game with your friends and not get squeezed. </p>
        {/* </Grid.Column> */}
        {/* <Grid.Column > */}
        {/* <Icon name="cut" size="big" /> */}
        <Divider />
        <Header as="h3"><span className="header-label">2. </span>We don't</Header>
          <p>We've created an automated marketplace for betting. You find a bet you want to make. We find someone who wants the opposite. Once we have a match, you have a bet.</p>
        {/* </Grid.Column>
         */}
         <Divider />
         <Header as="h3"><span className="header-label">3. </span>Get paid</Header>
          <p>Once you have a bet match we will let you know. If you win, get paid what you should. There is no juice, just you owning some dude in Toledo.</p>
         </div>
      </Grid.Row>
      {/* <Grid.Row columns={1} className="blue-row">
        <div className="wide-column-3-list">
        <Header as="h3"><span className="header-label">3. </span>Get more paid.</Header>
          <p>Once you have a bet, sweat game normally. If you win, get paid what you should. There is no juice. You just won a bet against some guy in Toledo.</p>
        </div>
      </Grid.Row> */}
    </Grid>
  </div>

  <div id="section-2" className="section-2" ref={ (divElement) => this.divElement = divElement}>
  {/* <div className="sf-wrapper-img-container"><img src={centerLogo} /></div> */}
  {this.state.message ? <div className="message">{this.state.messageContent}</div>:null}
      <div className="signup-container content-container">
        <p className="signup-message">We are flipping the sports betting business model on its head. Sign up now and we'll let you know the second you can get into our beta.</p>
        <div className="form-wrapper">
        <form onSubmit={this.handleForm.bind(this)}>
         <input type="email" placeholder='Enter Your Email Address' className="signup-input" value={this.state.email} onChange={this.handleChange} required />
         <Button fluid>SIGN UP</Button>
        </form>
        </div>
      </div>
  </div>

  <div id="section-3" className="section-3">
    <div className="hero-logo-container">
          <img src={oranges} className="oranges-img"/>
          <div className="bottom-logo-wrap">
          <span className="logo-front">Juice</span><span className="logo-back">Free</span>
          <div className="tagline">Rake-free sports betting</div>
          </div>
    </div>
    <div className="copyright">JuiceFree &copy; 2018</div>
  </div>


  </Layout>
  )
}

}

export default IndexPage
